export const GET_EYE_REFRACT_XML = "GET_EYE_REFRACT_XML"
export const HARDWARE_NIDEK_TYPE = "nidek"
export const HARDWARE_NIDEK_AND_DRS_PLUS_TYPE = 'nidek+drs'
export const HARDWARE_LUNEAU_TYPE = "luneau"
export const SAVE_HARDWARE_TYPE = "SAVE_HARDWARE_TYPE"
export const SET_ENGINE_DIAGNOSTIC = "SET_ENGINE_DIAGNOSTIC"
export const SET_HARDWARE_TYPE = "SET_HARDWARE_TYPE"

export enum MESSAGE_WEBSOCKET_DOMAIN {
  OPHTALMOLOGY = "ophtalmology",
  VITAL_CARD = "carte_vitale",
  INFOS = "infos",
}

export enum ACTION_WEBSOCKET_TYPE {
  CANCEL_CONSULTATION = "cancel_consultation",
  DIAGNOSTIC = "diagnostic",
  GET = "get",
  GET_EYE_REFRACT_XML = "get_eye_refract_xml",
  GET_EXAM_PDF = "get_exam_pdf",
  GET_HARDWARE_TYPE = "get_hardware_type",
  PUSH_EYE_REFRACT_CONFIGURATION = "push_eye_refract_configuration",
  PUSH_EXAM_CONFIGURATION = "push_exam_configuration",
  ALL_INFOS = "all_infos",
}

export const eyeRefract = 1
export const vx650 = 2

export const EYE_REFRACT_SOCKET_KEY = "eye_refract"
export const VX_650_SOCKET_KEY = "vx650";
