import { EditOutlined } from "@ant-design/icons"
import { AnyAction } from "@reduxjs/toolkit"
import { Col, Row, Input } from "antd"
import { useForm } from "antd/lib/form/Form"
import { clone, omit } from "lodash"
import { useEffect, useState } from "react"
import React from "react"
import config from "react-global-configuration"
import { useDispatch } from "react-redux"
import searchCityFullNameWithInseeCode from "api/SearchBirthLocationName"
import { Button } from "components/Button"
import { API_DATE_FORMAT } from "lib/utils"
import { Form, FormPage } from "components/Form/Form"
import { GetItemForm } from "components/Form/Input"
import { getNameOfCurrentInput, labelFromName } from "components/Form/utils"
import { GenericModal } from "components/Modal/Modal"
import { WarningModifyInformationModal } from "components/Modal/WarningModifyInformationModal"
import { languages } from "lib/languages"
import { Customer, Prospect, Relative, Gender } from "types/entity"
import { CustomerVerify, ResponsePutCustomerVerify } from "types/payload"
import { Message } from "types/redux"
import { cdn } from "core/cdn"
import actions from "./services/actions"
import { UpdateVerifyCode } from "./VerifyModification"
import styles from "./Profile.module.scss"
import { InputNames, InputFormProps, IDVerificationStatus } from "types/props"

export interface PatientInformationProps {
  loading: boolean
  change: (p: Partial<Customer>) => void
  changeRelative: (r: Partial<Relative>) => void
  patient: Customer
  relatives: Relative[]
  update_requires_action?: {
    message?: Message
    loading: boolean
    success: boolean
  } & CustomerVerify
}

const BtnLocked = () => {
  const [showModal, setShowModal] = useState(false)
  const TitleBlock = (
    <div className={styles.title__block}>
      <h1 className={styles.title__text}>{languages.verified_identity}</h1>
      <div className={styles.title__icon}>
        <img src={cdn("images/carte_vitale_illustration.svg")} alt="warning image" />
      </div>
    </div>
  )
  return (
    <>
      {showModal &&
        <GenericModal
          visible={showModal}
          noFooter={false}
          style={{ width: "95vw", height: 300 }}
          title={TitleBlock}
          acceptText={"Compris"}
          onAccept={() => setShowModal(false)}
          acceptButtonProps={{ danger: false, type: "primary" }}
        >
          <span className={styles.modal_body}>
            {languages.some_information_comes_from} <b>{languages.carte_vitale}</b> {languages.and_are_not} <b>{languages.modifiable}</b>
          </span>
        </GenericModal>
      }
      <div style={{ cursor: "pointer" }} onClick={() => setShowModal(true)}>
        <img height="70px" src={cdn("/images/btnLocked.svg")} alt="" />
      </div>
    </>

  )
}

export const FullNameDisplayer = ({ inputInfo, value, children, lock = false, loading }): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const onClick = () => {
    setShowModal(true)
  }

  return (
    <>
      {showModal &&
        <GenericModal
          visible={showModal}
          noFooter={false}
          style={{ width: "95vw", height: 300 }}
          title={languages.identity_data}
          acceptText={languages.finish}
          onAccept={() => setShowModal(false)}
          acceptButtonProps={{ danger: false, type: "primary" }}
        >
          {children}
        </GenericModal>
      }
      <Row gutter={[16, 16]} className={styles.Row}>
        <Col span="7" className={styles.infoLabel}>
          {inputInfo} :
        </Col>
        <Col span="13">
          <Input disabled value={value} style={{ color: "#7A7A7A" }} />
        </Col>
        <Col span="4">
          {lock ? <BtnLocked /> : <Button
            loading={loading}
            icon={<EditOutlined />}
            size="xsmall"
            onClick={onClick}
          >
            {languages.modify}
          </Button>}
        </Col>
      </Row>
    </>
  )
}

export const Modifier: React.FC<{
  name: string
  loading: boolean
  disabled?: boolean
  valuesToIntegrate?: Partial<Prospect>
  action?: (value: Partial<Prospect>) => void
  label?: string
  lock?: boolean;
  readOnly?: boolean;
}> = (props) => {
  const dispatch = useDispatch()
  const [visible, setVisibleModal] = useState(false)
  const [
    warningModifyInformationModalVisible,
    setWarningModifyInformationModalVisible,
  ] = useState(false)
  const inputs: InputFormProps[] = [
    {
      name: props.name,
      required: true,
      label:
        props.label || languages.modifyYour(getNameOfCurrentInput(props.name)),
    },
  ]

  if (props.name === "new-password") {
    inputs.unshift({
      name: "old-password",
      required: true,
      label: languages.InputYourOldPassword,
    })
  }

  if ([InputNames.BIRTH_LOCATION].includes(props.name as InputNames)) {
    inputs.push(
      {
        name: InputNames.INSEE_CODE,
        required: true,
        label: "",
        type: "hidden"
      }
    )
  }

  if (["phone", "email"].includes(props.name)) {
    inputs.push({
      name: "old-password",
      required: true,
      label: languages.InputYourPassword,
    })
  }
  const handleFinished = (values: Partial<Prospect>) => {

    if ([InputNames.BIRTH_LOCATION].includes(props.name as InputNames)) {
      values.birth_location = values.insee_code as string
    }

    dispatch(
      props.action
        ? (props.action({
          ...props.valuesToIntegrate,
          ...values,
        }) as any)
        : actions.changePatientRequest({
          ...props.valuesToIntegrate,
          ...values,
        })
    )
    setVisibleModal(false)
  }

  if (props.disabled) {
    return (
      <Row gutter={[16, 16]} className={styles.Row}>
        <Col span="7" className={styles.infoLabel}>
          {labelFromName(props.name)} :
        </Col>
        <Col span="15">
          <GetItemForm
            // To display new-password as a password
            name={getNameOfCurrentInput(props.name)}
            visible={true}
            disabled={true}
            readOnly={props.readOnly}
          />
        </Col>
      </Row>
    )
  }
  return (
    <>
      {!visible ? (
        <Row gutter={[16, 16]} className={styles.Row}>
          <Col span="7" className={styles.infoLabel}>
            {(props.name === InputNames.BIRTH_LOCATION) ?
              `${languages.birthLocation_with_insee_code} :` :
              `${labelFromName(props.name)} :`}
          </Col>
          <Col span="13">
            <GetItemForm
              // To display new-password as a password
              name={getNameOfCurrentInput(props.name)}
              visible={true}
              disabled={true}
              readOnly={props.readOnly}
            />
          </Col>
          <Col span="4">
            {props.lock ? <BtnLocked /> :
              <Button
                loading={props.loading}
                icon={<EditOutlined />}
                size="xsmall"
                onClick={() => {
                  if ([InputNames.FIRSTNAME, InputNames.LASTNAME].includes(props.name as InputNames)) {
                    setWarningModifyInformationModalVisible(true)
                  } else setVisibleModal(true)
                }}
              >
                {languages.modify}
              </Button>}
          </Col>
        </Row>
      ) : (
        <GenericModal
          visible={visible}
          noFooter={true}
          style={{ width: "95vw" }}
        >
          <FormPage
            onCancel={() => {
              setVisibleModal(false)
            }}
            acceptText={languages.modify}
            loading={props.loading}
            inputs={inputs}
            onFinish={handleFinished}
          />
        </GenericModal>
      )}
      <WarningModifyInformationModal
        visible={warningModifyInformationModalVisible}
        onAccept={() => {
          setWarningModifyInformationModalVisible(false)
          setVisibleModal(true)
        }}
        onCancel={() => setWarningModifyInformationModalVisible(false)}
      />
    </>
  )
}

export const PatientInformation: React.FC<PatientInformationProps> = (
  props
): JSX.Element => {
  const [formRef] = useForm()
  const [requireCodeVisible, setRequireCodeVisible] = useState(false)

  const [verified_fields] = useState(props.patient.id_verification_fields)
  const [isVerified] = useState(props.patient.id_verification_status === IDVerificationStatus.VERIFIED || props.patient.id_verification_status === IDVerificationStatus.PROCESSING)

  const lockAll = isVerified && verified_fields.sex && verified_fields.first_birth_firstname && verified_fields.birth_lastname;
  const [valuesForm, setValuesForm] = useState(
    {
      ...omit(props.patient, "updated_at", "created_at"),
      // insee_code: props.patient.birth_location,
      password: "",
      email:
        props.patient?.email === config.get("mock.noemail")
          ? undefined
          : props.patient.email,
    }
  )

  const getValue = (firstname: string, lastname: string) => {

    if (!formRef.getFieldValue(firstname) || !formRef.getFieldValue(lastname)) {
      return languages.not_informed
    }
    const fullName = `${formRef.getFieldValue(firstname)} ${formRef.getFieldValue(lastname)}`
    return `${formRef.getFieldValue(InputNames.GENDER) === Gender.FEMALE ? 'Mme' : 'M'} ${fullName}`
  }

  useEffect(() => {
    let { patient } = props;
    const birthDate = (patient.birthdate).format(
      API_DATE_FORMAT
    )
    if (/^\d+$/.test(props.patient.birth_location)) {
      if (patient.birth_location === "99999") {
        patient = { ...patient, [InputNames.BIRTH_LOCATION]: languages.unknown }
        formRef.setFieldValue(InputNames.BIRTH_LOCATION, `${languages.unknown} (99999)`)
      } else {
        searchCityFullNameWithInseeCode(patient.birth_location, birthDate).then(
          res => {
            formRef.setFieldValue(InputNames.BIRTH_LOCATION, `${res.locations[0].name} (${res.locations[0].insee_code})`)
          }
        ).catch((error) => console.error(error, { path: "locations/public/search" }))
      }
    }
    // should be updated after the patient edit a value
    formRef.setFieldsValue({ ...patient })
  }, [props.patient])

  useEffect(() => {
    if (props.update_requires_action) setRequireCodeVisible(true)
    else setRequireCodeVisible(false)
  }, [props.update_requires_action])

  return (
    <>
      <Form
        form={formRef}
        onValuesChange={(values) => setValuesForm(values)}
        initialValues={valuesForm}
        style={{ marginTop: 20 }}
      >
        <FullNameDisplayer
          inputInfo={languages.birth_names}
          value={getValue(InputNames.FIRST_BIRTH_FIRSTNAME, InputNames.BIRTH_LASTNAME)}
          loading={props.loading} lock={lockAll}>
          <Modifier loading={props.loading} name={InputNames.GENDER} lock={isVerified && verified_fields?.sex} />
          <Modifier loading={props.loading} name={InputNames.FIRST_BIRTH_FIRSTNAME} lock={isVerified && verified_fields?.first_birth_firstname} />
          <Modifier loading={props.loading} name={InputNames.BIRTH_LASTNAME} lock={isVerified && verified_fields?.birth_lastname} />
        </FullNameDisplayer>

        <FullNameDisplayer
          inputInfo={languages.pseudo_names}
          value={getValue(InputNames.FIRSTNAME, InputNames.LASTNAME)}
          loading={props.loading} lock={false}>
          <Modifier loading={props.loading} name={InputNames.FIRSTNAME} />
          <Modifier loading={props.loading} name={InputNames.LASTNAME} />
        </FullNameDisplayer>
        <Modifier loading={props.loading} name={InputNames.Email} />
        <Modifier loading={props.loading} name={InputNames.BIRTHDATE} lock={isVerified && verified_fields?.birthdate} />
        <Modifier loading={props.loading} name={InputNames.BIRTH_LOCATION} lock={isVerified && verified_fields?.birth_location} readOnly />
        <Modifier loading={props.loading} name={InputNames.PHONE} />
        <Modifier loading={props.loading} name={InputNames.NEW_PASSWORD} />
      </Form>
      <UpdateVerifyCode
        visible={requireCodeVisible}
        cancel={() => setRequireCodeVisible(false)}
        {...props.update_requires_action}
      />
    </>
  )
}
