import config from "react-global-configuration"
import { call, put, select, takeLatest, takeLeading, take, delay } from "redux-saga/effects"

import { CALL_HAS_CHANGED } from "containers/Call/services/constants"
import { CALL_FINISHED } from "containers/Call/services/saga"
import { handleApiErrors } from "lib/api-errors"
import { request } from "lib/request"
import { DoctorType } from "types/entity"
import { Call, CallState } from "types/payload"
import { Action, GFlow } from "types/redux"
import { SocketStore } from "types/store"


import {
  SAVE_CONFIGURATION_ID,
  SOCKET_ERROR_CLOSED,
  SOCKET_LAUNCH_WORKFLOW,
  SOCKET_OPTICIAN_MANAGER,
  SOCKET_SEND_CANCELED_CALL,
} from "../constants"
import {
  SET_ENGINE_DIAGNOSTIC,
  SAVE_HARDWARE_TYPE,
  SET_HARDWARE_TYPE,
} from "../../socket/optic/contants";
import {
  getBinaryFileContent,
  initSocket,
  sendMessage,
  setBinaryFileContent,
} from "../saga"
import {
  EyeRefractPayload,
  getDocumentEndpoint,
  getFileExtByType,
  ReadyState,
  SocketPayload,
} from "../utils"
import { saveHardwareType, setDiagnostic } from "./actions"
import {
  ACTION_WEBSOCKET_TYPE,
  eyeRefract,
  EYE_REFRACT_SOCKET_KEY,
  HARDWARE_LUNEAU_TYPE,
  HARDWARE_NIDEK_TYPE,
  HARDWARE_NIDEK_AND_DRS_PLUS_TYPE,
  MESSAGE_WEBSOCKET_DOMAIN,
  vx650,
  VX_650_SOCKET_KEY,
} from "./contants"

let workflowIsStarted: boolean = false
let sendBinaryRetryNumber: number = 3

function* sendCancelCall() {
  const id_eye_refract: string = yield select(
    (state) => state.socket?.id_eye_refract
  )
  const id_vx_650: string = yield select((state) => state.socket?.id_vx_650)
  yield call(sendMessage, {
    type: MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY,
    action: ACTION_WEBSOCKET_TYPE.CANCEL_CONSULTATION,
    body: {
      id_eye_refract,
      id_vx_650,
    },
  })
}

function* getHardwareType() {
  yield sendMessage({
    type: MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY,
    action: ACTION_WEBSOCKET_TYPE.GET_HARDWARE_TYPE,
    body: {},
  })
}

function* getOrSetHardwareType() {
  let currentHardwareType = yield select(({ socket }: { socket: SocketStore }) => socket.hardwareType);
  if (!currentHardwareType) {
    yield getHardwareType();
    yield take(SAVE_HARDWARE_TYPE);
    return yield select(({ socket }: { socket: SocketStore }) => socket.hardwareType);
  }
  return currentHardwareType;
}

function* launchWorkflow(): any {
  const client = yield call(initSocket)
  if (!client) {
    console.warn("[launchReaderCard] client not ready")
    return
  }
  if (client.readyState !== ReadyState.OPEN) {
    console.warn("[launchReaderCard] client not ready")
  } else {
    const hardwareType = yield getOrSetHardwareType();
    console.log('[launchWorkflow] for : ', hardwareType);
    if (hardwareType === HARDWARE_NIDEK_TYPE || hardwareType === HARDWARE_NIDEK_AND_DRS_PLUS_TYPE) {
      yield launchNidekWorkflow(hardwareType)
    } else if (hardwareType === HARDWARE_LUNEAU_TYPE) {
      yield launchLuneauWorkflow();
    } else {
      console.error("No hardware type found on this engine")
    }
  }
}

function* launchERWorkflow(): any {
  const machineConfiguration = config.get("optic.get.machineConfiguration")
  try {
    const res = yield request(`${machineConfiguration}/${eyeRefract}`, {
      method: "GET",
      additionalHeaders: { "Content-Type": "application/xml" },
      responseFormat: "xml",
    }).then((response: Response) => {
      return response.text().then((xml: string) => {
        return {
          headers: {
            id: response.headers.get("X-Path-Id"),
          },
          body: xml,
          statusCode: response.status,
        }
      })
    })
    if (res.statusCode === 200) {
      const payload = {
        type: MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY,
        action: ACTION_WEBSOCKET_TYPE.PUSH_EYE_REFRACT_CONFIGURATION,
        body: {
          id: res.headers.id,
          content_file: btoa(res.body),
        },
      }
      yield put({
        type: SAVE_CONFIGURATION_ID,
        payload: { id_eye_refract: res.headers.id },
      })
      yield sendMessage(payload)
    }
  } catch (error) {
    console.error(error, {
      route: `${machineConfiguration}/${eyeRefract}`,
    })
  }
}
function* launchLuneauWorkflow(): any {
  yield launchERWorkflow()
  yield launchExamWorkflow()
}

function* launchNidekWorkflow(hardwareType: string): any {
  yield launchERWorkflow();
  if (hardwareType === HARDWARE_NIDEK_AND_DRS_PLUS_TYPE) {
    yield launchDRSPlusWorflow();
  }
}

function* launchDRSPlusWorflow(): any {
  try {
    const payload = {
      type: MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY,
      action: ACTION_WEBSOCKET_TYPE.GET_EXAM_PDF,
      body: {
        id: "",
        user_token: sessionStorage.getItem('X-USER-Token') || "",
        archive: true
      }
    }
    yield sendMessage(payload)
  } catch (error) {
    console.error(error, {
      route: `launchDRSPlusWorkflow`
    })
  }
}

function createFile(filename: string, fileContent: Blob, type: string) {
  const fileExt = getFileExtByType(type)
  const myFile = new File([fileContent], filename, {
    type: `application/${fileExt}`,
  })

  const file = new FormData()
  file.append("filename", myFile)

  return file
}

function isBlobEmtpy(blob) {
  return !blob || blob instanceof Blob === false || new Blob([blob]).size === 0;
}


function* setDocument({ filename = "" }: EyeRefractPayload, type: ACTION_WEBSOCKET_TYPE) {
  let blob = yield getBinaryFileContent()
  // make sure we received binary file content before sending
  if (sendBinaryRetryNumber !== 0 && isBlobEmtpy(blob)) {
    const documentType = type === ACTION_WEBSOCKET_TYPE.GET_EXAM_PDF ? "exam" : "eye_refract";
    yield delay(1000);
    yield requestDocumentToWebSocket(documentType, false)
    sendBinaryRetryNumber -= 1;
    return;
  }

  if (isBlobEmtpy(blob)) {
    throw `Le fichier ${filename} de type : ${type} est vide et n'a pas été envoyé`;
  }


  const vendor: string = yield select((state) => state.socket.hardwareType === HARDWARE_NIDEK_AND_DRS_PLUS_TYPE ? HARDWARE_NIDEK_TYPE : state.socket.hardwareType)
  const endpointUrl = getDocumentEndpoint(type)
  const file = createFile(filename, blob, type)
  yield fetch(`api/${endpointUrl}`, {
    method: "POST",
    headers: {
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      vendor,
    },
    body: file,
  })
    .then(handleApiErrors)
    .then(() => {
      setBinaryFileContent(undefined)
    })
    .catch((error) => {
      setBinaryFileContent(undefined)
      throw error
    })
  return
}

function* saveHardwareEngine(payload) {
  console.log('[Saving hardware Type] :', payload?.type);
  if (payload?.type === HARDWARE_NIDEK_TYPE || payload?.type === HARDWARE_NIDEK_AND_DRS_PLUS_TYPE) {
    yield put(saveHardwareType(payload?.type))
  } else if (payload?.type === HARDWARE_LUNEAU_TYPE) {
    yield put(saveHardwareType(HARDWARE_LUNEAU_TYPE))
  }
}

function* requestDocumentToWebSocket(documentType: "eye_refract" | "exam", archive = true) {
  let message: {
    action:
    | ACTION_WEBSOCKET_TYPE.GET_EYE_REFRACT_XML
    | ACTION_WEBSOCKET_TYPE.GET_EXAM_PDF
    body: { id: string; user_token: string, archive?: boolean }
  }
  switch (documentType) {
    case "eye_refract":
      const id_eye_refract = yield select(
        (state) => state.socket.id_eye_refract
      ) || 1
      message = {
        action: ACTION_WEBSOCKET_TYPE.GET_EYE_REFRACT_XML,
        body: {
          id: id_eye_refract,
          user_token: sessionStorage.getItem("X-USER-Token") || "",
          archive
        },
      }
      break
    case "exam":
      const id_vx_650 = yield select((state) => state.socket.id_vx_650) || 1
      message = {
        action: ACTION_WEBSOCKET_TYPE.GET_EXAM_PDF,
        body: {
          id: id_vx_650,
          user_token: sessionStorage.getItem("X-USER-Token") || "",
          archive
        },
      }
      break
  }
  yield sendMessage({
    type: MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY,
    ...message,
  })
}
function* opticianDocumentFlow(websocketResponse) {
  const { payload, type } = websocketResponse

  try {
    yield setDocument({ filename: payload.filename }, type)
  } catch (error) {
    const blob = yield getBinaryFileContent()
    console.error(error, {
      route: `wss:setDocument/${payload.filename}`,
      response: `trying to send blob : ${blob}`
    })
  }
}

export function* opticianSocketManager(
  action: Action<SocketPayload>
): GFlow<any> {
  const { payload, type, result } = action.payload
  switch (type) {
    case ACTION_WEBSOCKET_TYPE.GET_HARDWARE_TYPE:
      yield saveHardwareEngine(payload)
      break
    case ACTION_WEBSOCKET_TYPE.PUSH_EYE_REFRACT_CONFIGURATION:
      // number of retry if document not send the first time
      sendBinaryRetryNumber = 3;
      yield requestDocumentToWebSocket("eye_refract")
      break
    case ACTION_WEBSOCKET_TYPE.PUSH_EXAM_CONFIGURATION:
      // number of retry if document not send the first time
      sendBinaryRetryNumber = 3;
      yield requestDocumentToWebSocket("exam")
      break
    case ACTION_WEBSOCKET_TYPE.GET_EYE_REFRACT_XML:
    case ACTION_WEBSOCKET_TYPE.GET_EXAM_PDF:
      if (result === "OK") yield opticianDocumentFlow(action.payload)
      else console.error(`error setting document in ${type}`, {
        route: `wss::opticianSocketManager:getDocument`
      })
      break
    case ACTION_WEBSOCKET_TYPE.DIAGNOSTIC:
      // this code handle the fact that Nidek has no VX => allways true
      if (result === "OK") {
        yield put({ type: SET_ENGINE_DIAGNOSTIC, payload: { [EYE_REFRACT_SOCKET_KEY]: { status: "OK" }, [VX_650_SOCKET_KEY]: { status: "OK" } } });
      } else {
        yield put(setDiagnostic(payload))
      }
    default:
      break;
  }
}

function* stopWorkflow() {
  workflowIsStarted = false
}

function* handleCallStarted(action: Action<Call>): any {
  const currentDoctorCategoryId = action.payload?.category_id
  const state = action.payload?.state
  if (
    state === CallState.STARTED &&
    currentDoctorCategoryId === DoctorType.ORTHOPTIST
  ) {
    if (!workflowIsStarted) {
      workflowIsStarted = true
      console.log("[Launch] optic Workflow")
      yield call(launchWorkflow)
    }
  } else if (CALL_FINISHED.includes(state)) {
    workflowIsStarted = false
    yield put({ type: SOCKET_SEND_CANCELED_CALL })
  }
}

function* launchExamWorkflow(): any {
  const machineConfiguration = config.get("optic.get.machineConfiguration")
  try {
    const res = yield request(`${machineConfiguration}/${vx650}`, {
      method: "GET",
      responseFormat: "xml",
    }).then((response: Response) => {
      return response.text().then((xml: string) => {
        return {
          headers: {
            id: response.headers.get("X-Path-Id"),
          },
          body: xml,
          statusCode: response.status,
        }
      })
    })
    if (res.statusCode === 200) {
      const payload = {
        type: MESSAGE_WEBSOCKET_DOMAIN.OPHTALMOLOGY,
        action: ACTION_WEBSOCKET_TYPE.PUSH_EXAM_CONFIGURATION,
        body: {
          id: res.headers.id,
          content_file: btoa(res.body),
        },
      }
      yield put({
        type: SAVE_CONFIGURATION_ID,
        payload: { id_vx_650: res.headers.id },
      })
      yield sendMessage(payload)
    }
  } catch (error) {
    console.error(error, {
      route: config.get("optic.get.machineConfiguration")
    })
  }
}

export default function* opticSocketSaga(): any {
  yield takeLatest(CALL_HAS_CHANGED, handleCallStarted)
  yield takeLatest(SOCKET_LAUNCH_WORKFLOW, launchWorkflow)
  yield takeLeading(SOCKET_SEND_CANCELED_CALL, sendCancelCall)
  yield takeLeading(SOCKET_OPTICIAN_MANAGER, opticianSocketManager)
  yield takeLeading(SOCKET_ERROR_CLOSED, stopWorkflow)
  yield takeLeading(SET_HARDWARE_TYPE, getHardwareType)
}
