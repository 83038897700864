import { GET_EYE_REFRACT_XML, SAVE_HARDWARE_TYPE, SET_ENGINE_DIAGNOSTIC } from "./contants"
import { Diagnostic } from "types/payload"

export function saveHardwareType(hardwareType: "luneau" | "nidek" | "nidek+drs") {
  return {
    type: SAVE_HARDWARE_TYPE,
    payload: hardwareType,
  }
}
export function getEyeRefractDone() {
  return {
    type: GET_EYE_REFRACT_XML,
  }
}

export function setDiagnostic(payload: Diagnostic) {
  return {
    type: SET_ENGINE_DIAGNOSTIC,
    payload
  }
}
